_cruxUtils._cruxLocale = {
"crux.no.bc.message":"\u5229\u7528\u53ef\u80fd\u306a\u6982\u8981\u304c\u3042\u308a\u307e\u305b\u3093\u3002\u30ec\u30b3\u30fc\u30c9\u3092\u30af\u30ea\u30c3\u30af\u3057\u3066\u8a73\u7d30\u3092\u8868\u793a",
"crm.appointments.status.markcompleted1": "\u5b8c\u4e86\u3068\u3057\u3066\u8a18\u9332\u3059\u308b",
"crm.mass.actions.all.selected": "<span class=\"dIB vam\">\u3053\u306e\u4e00\u89a7\u306e\u3059\u3079\u3066\u306e</span><span class='openSansSemiBold mL3 mR3 bold dIB vam recordCountHt'>{0}</span><span class=\"dIB vam\"> {1}\u304c\u9078\u629e\u3055\u308c\u3066\u3044\u307e\u3059\u3002</span>",
"crm.module.selectall": "\u3053\u306e\u4e00\u89a7\u306e\u3059\u3079\u3066\u306e{0}\u3092\u9078\u629e\u3059\u308b",
"records": "\u30c7\u30fc\u30bf",
"crm.record.not.accessible": "\u30c7\u30fc\u30bf",
"crm.view.calls.recording.err.msg": "\u8a72\u5f53\u306e\u9332\u97f3\u30c7\u30fc\u30bf\u306b\u306f\u3001\u518d\u751f\u53ef\u80fd\u306a\u97f3\u58f0\u306eURL\u304c\u767b\u9332\u3055\u308c\u3066\u3044\u307e\u305b\u3093\u3002",
"crm.listview.selected.records": "<span class='openSansSemiBold mL3 f13 bold vam dIB'>{0}\u4ef6\u306e</span><span class=\"mL3 seletcedRecords\">{1}\u3092\u9078\u629e\u3057\u307e\u3057\u305f</span>",
"totalrecords": "\u30c7\u30fc\u30bf\u6570\u306e\u5408\u8a08",
"crm.listview.options.text.clip": "\u30c6\u30ad\u30b9\u30c8\u3092\u7701\u7565\u3059\u308b",
"crm.listview.options.text.wrap": "\u30c6\u30ad\u30b9\u30c8\u3092\u6298\u308a\u8fd4\u3059",
"crm.listview.options.reset.width": "\u5217\u306e\u30b5\u30a4\u30ba\u3092\u30ea\u30bb\u30c3\u30c8\u3059\u308b",
"crm.listview.options.manage": "\u8868\u793a\u3059\u308b\u5217\u3092\u5909\u66f4\u3059\u308b",
"crm.listview.customview.locked": "\u3053\u306e\u5185\u5bb9\u306f\u30ed\u30c3\u30af\u3055\u308c\u3066\u3044\u308b\u305f\u3081\u3001\u5217\u3092\u30ab\u30b9\u30bf\u30de\u30a4\u30ba\u3059\u308b\u3053\u3068\u306f\u3067\u304d\u307e\u305b\u3093",
"crm.api.apidashboard.SortBy": "\u4e26\u3079\u66ff\u3048\u6761\u4ef6\uff1a",
"crm.privacy.listview.consent.locked": "\u30c7\u30fc\u30bf\u3092\u51e6\u7406\u304b\u3089\u30ed\u30c3\u30af\u3057\u307e\u3057\u305f",
"crm.label.reschedule.call": "{0}\u4e88\u5b9a\u3092\u5909\u66f4\u3059\u308b",
"crm.button.edit": "\u7de8\u96c6\u3059\u308b",
"Delete": "\u524a\u9664",
"crm.appointments.status.markcancel": "{0}\u3092\u30ad\u30e3\u30f3\u30bb\u30eb\u3059\u308b",
"crm.appointments.status.markreschedule": "{0}\u306e\u65e5\u6642\u3092\u5909\u66f4\u3059\u308b",
"crm.label.cancel.call": "{0}\u3092\u30ad\u30e3\u30f3\u30bb\u30eb\u3059\u308b",
"crm.label.mark.completed": "\u5b8c\u4e86\u306b\u3059\u308b",
"crm.listview.customview.locked": "\u3053\u306e\u5185\u5bb9\u306f\u30ed\u30c3\u30af\u3055\u308c\u3066\u3044\u308b\u305f\u3081\u3001\u5217\u3092\u30ab\u30b9\u30bf\u30de\u30a4\u30ba\u3059\u308b\u3053\u3068\u306f\u3067\u304d\u307e\u305b\u3093",
"pf.deleted.state": "\u72b6\u614b\u3092\u524a\u9664\u3059\u308b",
"crm.project.integration.title": "Zoho Projects",
"crm.integ.sync.message": "{0}\u3068\u540c\u671f",
"crm.schedules.prefix.in": "\u5b9f\u884c\u307e\u3067\u306e\u6642\u9593\uff1a",
"crm.record.record.locked.other": "\u4ed6\u306e\u6a29\u9650\u306e\u30e6\u30fc\u30b6\u30fc\u306b\u5bfe\u3057\u3066\u3001\u30c7\u30fc\u30bf\u304c\u30ed\u30c3\u30af\u3055\u308c\u3066\u3044\u307e\u3059\u3002",
"crm.record.lock.record.locked": "\u30c7\u30fc\u30bf\u304c\u30ed\u30c3\u30af\u3055\u308c\u3066\u3044\u307e\u3059\u3002",
"crm.approvalProcess.label.waitingForApproval": "\u627f\u8a8d\u5f85\u3061",
"crm.reviewprocess.record.review.pending": "\u30ec\u30d3\u30e5\u30fc\u5f85\u3061",
"crm.approvalProcess.label.waitingForFindAndMerge": "\u91cd\u8907\u30c7\u30fc\u30bf\u3092\u7d71\u5408\u4e2d\u3067\u3059\u3002",
"crm.privacy.listview.consent.locked": "\u30c7\u30fc\u30bf\u3092\u51e6\u7406\u304b\u3089\u30ed\u30c3\u30af\u3057\u307e\u3057\u305f",
"crm.zia.vision.processing": "Zia\u306b\u3088\u308a\u3001\u753b\u50cf\u304c\u691c\u8a3c\u3055\u308c\u3066\u3044\u307e\u3059\u3002",
"crm.zia.vision.rejected.msg": "\u753b\u50cf\u691c\u8a3c\u306b\u5931\u6557\u3057\u305f\u305f\u3081\u3001\u30c7\u30fc\u30bf\u304c\u5374\u4e0b\u3055\u308c\u307e\u3057\u305f\u3002",
"crm.zia.vision.record.failure.msg": "\u753b\u50cf\u691c\u8a3c\u306b\u5931\u6557\u3057\u305f\u305f\u3081\u3001\u30c7\u30fc\u30bf\u306f\u627f\u8a8d\u5f85\u3061\u3067\u3059\u3002",
"crm.alert.max.cvcols.reached": "\u6700\u5927{0}\u4ef6\u306e\u9805\u76ee\u3092\u9078\u629e\u3067\u304d\u307e\u3059\u3002",
"crm.label.small.tags": "\u30bf\u30b0",
"crm.label.edit.module": "{0}\u3092\u7de8\u96c6\u3059\u308b",
"crm.social.integ.no.result": "\u8a72\u5f53\u3059\u308b\u30c7\u30fc\u30bf\u306f\u898b\u3064\u304b\u308a\u307e\u305b\u3093\u3067\u3057\u305f",
"custommodule.crmfield.fl.name": "{0}\u540d",
"Appointment": "\u4e88\u7d04",
"crm.button.create.call": "\u901a\u8a71\u3092\u4f5c\u6210\u3059\u308b",
"crm.module.create": "{0}\u3092\u4f5c\u6210",
"crm.customview.activty.badge": "\u6d3b\u52d5\u30e9\u30d9\u30eb",
"crm.button.callnow": "\u901a\u8a71\u3092\u767a\u4fe1\u3059\u308b",
"crm.manualcalllist.complete.call": "\u901a\u8a71\u3092\u8a18\u9332\u3059\u308b",
"crm.label.schedule.call": "\u901a\u8a71\u3092\u4e88\u7d04\u3059\u308b",
"crm.filter.label.replied":"\u8fd4\u4fe1\u6e08\u307f",
"bytes": "\u30d0\u30a4\u30c8",
"crm.field.label.user.lookup": "\u30e6\u30fc\u30b6\u30fc",
"": "crm.pm.template2.name",
"crm.translation.refresh.cases": "\u5b9f\u884c\u3057\u3088\u3046\u3068\u3057\u305f\u64cd\u4f5c\u3092\u5b8c\u4e86\u3067\u304d\u307e\u305b\u3093\u3067\u3057\u305f\u3002\u30da\u30fc\u30b8\u3092\u66f4\u65b0\u3057\u3066\u3001\u3082\u3046\u4e00\u5ea6\u304a\u8a66\u3057\u304f\u3060\u3055\u3044\u3002",
"crm.project.sync.notes.info": "\u95a2\u9023\u4ed8\u3051\u3089\u308c\u3066\u3044\u308b\u30d7\u30ed\u30b8\u30a7\u30af\u30c8\u306e\u30bf\u30b9\u30af\u306e\u30b3\u30e1\u30f3\u30c8\u306e\u307f\u304c\u8868\u793a\u3055\u308c\u307e\u3059\u3002",
"crux.note.content.limit.warning.msg":"{0}\u6587\u5b57\u306f\u6ce8\u8a18\u306e\u5185\u5bb9\u306b\u306e\u307f\u4fdd\u5b58\u3067\u304d\u307e\u3059",
"crux.note.formatting.options":"\u66f8\u5f0f\u8a2d\u5b9a\u30aa\u30d7\u30b7\u30e7\u30f3",
"crm.label.required": "\u5fc5\u9808",
"crux.condition.not.belongs.to":"{0}\u306b\u306f\u5c5e\u3057\u307e\u305b\u3093",
"crm.project.sync.notes.info": "\u95a2\u9023\u4ed8\u3051\u3089\u308c\u3066\u3044\u308b\u30d7\u30ed\u30b8\u30a7\u30af\u30c8\u306e\u30bf\u30b9\u30af\u306e\u30b3\u30e1\u30f3\u30c8\u306e\u307f\u304c\u8868\u793a\u3055\u308c\u307e\u3059\u3002",
"crm.sf.permission.no.edit": "\u3053\u306e\u30b5\u30d6\u30d5\u30a9\u30fc\u30e0\u3092\u7de8\u96c6\u3059\u308b\u6a29\u9650\u304c\u3042\u308a\u307e\u305b\u3093",
"crm.sf.permission.no.create": "\u3053\u306e\u30b5\u30d6\u30d5\u30a9\u30fc\u30e0\u3092\u4f5c\u6210\u3059\u308b\u6a29\u9650\u304c\u3042\u308a\u307e\u305b\u3093",
"crm.sf.permission.no.delete": "\u3053\u306e\u30b5\u30d6\u30d5\u30a9\u30fc\u30e0\u3092\u524a\u9664\u3059\u308b\u6a29\u9650\u304c\u3042\u308a\u307e\u305b\u3093",
"crux.note.this.note":"\u3053\u306e\u30e1\u30e2",
"KB": "KB",
"MB": "MB",
"current.logged.in.user.role": "\u30ed\u30b0\u30a4\u30f3\u4e2d\u306e\u30e6\u30fc\u30b6\u30fc\u306e\u5f79\u8077",
"Unit Price": "\u5358\u4fa1",
"Qty in Stock": "\u5728\u5eab\u91cf",
"crm.label.Product Code": "\u5546\u54c1\u30b3\u30fc\u30c9",
"TotalAfterDiscount": "\u5272\u5f15\u5f8c\u306e\u5408\u8a08",
"crm.lookupfilter.entity.errormsg": "\u9078\u629e\u3057\u305f{0}\u306f\u30eb\u30c3\u30af\u30a2\u30c3\u30d7\u306e\u6761\u4ef6\u3092\u6e80\u305f\u3057\u3066\u3044\u307e\u305b\u3093\u3002",
"crm.iamexception.maxlen": "{1}\u306b\u5bfe\u3057\u3066\u5165\u529b\u3067\u304d\u308b\u6587\u5b57\u6570\u306f\u3001{0}\u6587\u5b57\u4ee5\u4e0b\u3067\u3059\u3002",
"crm.record.locking.permission.denied": "\u30c7\u30fc\u30bf\u304c\u30ed\u30c3\u30af\u3055\u308c\u3066\u3044\u308b\u305f\u3081\u3001\u3053\u306e\u64cd\u4f5c\u3092\u5b9f\u884c\u3067\u304d\u307e\u305b\u3093\u3002",
"List Price": "\u8ca9\u58f2\u4fa1\u683c",
"crm.label.no.more.records": "\u4ed6\u306b\u30c7\u30fc\u30bf\u306f\u3042\u308a\u307e\u305b\u3093",
"crm.workflow.rule.created.dateAndTime":"{0}\uff08{1} / {2}\uff09",
"crux.new.note.msg":"\u3053\u306e\u30e1\u30e2\u306f\u4f55\u3067\u3059\u304b\uff1f",
"crm.image.uploaded":"\u753b\u50cf\u3092\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u3057\u307e\u3057\u305f",
"crm.imageupload.drag.drop.here":"\u3053\u3053\u306b\u753b\u50cf\u3092\u30c9\u30e9\u30c3\u30b0\uff06\u30c9\u30ed\u30c3\u30d7\u3057\u3066\u304f\u3060\u3055\u3044",
"crm.fileupload.drag.drop.here":"\u30d5\u30a1\u30a4\u30eb\u3092\u3053\u3061\u3089\u306b\u30c9\u30e9\u30c3\u30b0&\u30c9\u30ed\u30c3\u30d7\u3057\u3066\u304f\u3060\u3055\u3044",
"crm.fileupload.wait.msg":"\u9078\u629e\u3057\u305f\u30d5\u30a1\u30a4\u30eb\u304c\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u3055\u308c\u308b\u307e\u3067\u304a\u5f85\u3061\u304f\u3060\u3055\u3044",
"crm.fileupload.attach.fail":"\u6dfb\u4ed8\u306b\u5931\u6557\u3057\u307e\u3057\u305f",
"crm.fileupload.support.format":"Zia \u306e\u30c8\u30ec\u30fc\u30cb\u30f3\u30b0\u3067\u30b5\u30dd\u30fc\u30c8\u3055\u308c\u3066\u3044\u308b\u30d5\u30a1\u30a4\u30eb\u5f62\u5f0f (jpeg\u3001jpg\u3001png\u3001pdf)",
"custmr.prtl.user.role": "\u30dd\u30fc\u30bf\u30eb\u30e6\u30fc\u30b6\u30fc",
"crm.subform.empty.alert.header":"\u7a7a\u306e\u884c\u306f\u4fdd\u5b58\u3067\u304d\u307e\u305b\u3093",
"crm.crud.lookup.module.inaccessible": "\u3053\u306e\u53c2\u7167\u5148\u306e\u30bf\u30d6\u306b\u30a2\u30af\u30bb\u30b9\u3059\u308b\u6a29\u9650\u304c\u3042\u308a\u307e\u305b\u3093",
"crm.crud.lookup.inaccessible.record": "\u30c7\u30fc\u30bf\u304c\u5b58\u5728\u3057\u306a\u3044\u304b\u3001\u30a2\u30af\u30bb\u30b9\u3067\u304d\u307e\u305b\u3093",
"crm.custombutton.valid.weburl.check": "\u5024\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044\u3002",
"crm.label.subform.goto.top": "\u5148\u982d\u306b\u79fb\u52d5\u3059\u308b",
"crm.label.subform.row.show.all": "\u3059\u3079\u3066\u8868\u793a\u3059\u308b\uff08{0}\uff09",
"cpq.pr.nodiscount": "\u95a2\u9023\u4ed8\u3051\u3089\u308c\u3066\u3044\u308b\u5272\u5f15\u306f\u3042\u308a\u307e\u305b\u3093",
"crm.inventory.lineitem.no.pricebook.new": "\u95a2\u9023\u4ed8\u3051\u3089\u308c\u3066\u3044\u308b{0}\u306f\u3042\u308a\u307e\u305b\u3093\u3002",
"crm.label.subform.allowedlimit": "\u4e0a\u9650\u306e{0}\u4ef6\u306b\u9054\u3057\u307e\u3057\u305f",
"crm.reports.integ.okay": "OK",
"crm.crud.subform.deleted.record": "\u66f4\u65b0\u3057\u3088\u3046\u3068\u3057\u3066\u3044\u308b\u30b5\u30d6\u30d5\u30a9\u30fc\u30e0\u306e\u884c\u306f\u3001\u3059\u3067\u306b\u524a\u9664\u3055\u308c\u3066\u3044\u307e\u3059\u3002\u30da\u30fc\u30b8\u3092\u66f4\u65b0\u3057\u3066\u304b\u3089\u3001\u3082\u3046\u4e00\u5ea6\u304a\u8a66\u3057\u304f\u3060\u3055\u3044\u3002",
"crux.criteria.fieldlabel.valid.check":"\u6709\u52b9\u306a\u30d5\u30a3\u30fc\u30eb\u30c9\u30e9\u30d9\u30eb\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044\u3002",
"crm.subform.delete.confirm.header":"サブフォーム行を削除",
"crm.subform.delete.confirm.msg": "{0}で選択した行を削除してもよろしいですか?",
"crm.subform.delete.tooltip.msg" :"行は削除できません。{0}には、少なくとも1つの行エントリが必要です。",
"crm.orchestration.error.selectvalue": "\u5024\u3092\u9078\u629e\u3057\u3066\u304f\u3060\u3055\u3044",
	"crm.attach.upload.image":"\u753b\u50cf\u3092\u6dfb\u4ed8\u3059\u308b",//no i18n
"crm.general.select.photo":"\u5199\u771f\u3092\u9078\u629e",//no i18n
"crm.imageupload.wait.msg":"\u9078\u629e\u3057\u305f\u753b\u50cf\u304c\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u3055\u308c\u308b\u307e\u3067\u304a\u5f85\u3061\u304f\u3060\u3055\u3044\u3002",//no i18n
"crm.image.filepicker.desc.draganddrop":"\u753b\u50cf\u3092\u3053\u3061\u3089\u306b\u30c9\u30e9\u30c3\u30b0\uff06\u30c9\u30ed\u30c3\u30d7\u3057\u3066\u304f\u3060\u3055\u3044\u3002",//no i18n
"crm.image.filepicker.desc.browse":"\u30af\u30ea\u30c3\u30af\u3067\u753b\u50cf\u3092\u53c2\u7167\u2026\u2026",//no i18n
"crm.attach.upload.userinfo":"\u5408\u8a08\u30b5\u30a4\u30ba\u306e\u4e0a\u9650\u306f<span class",//no i18n
"crm.image.supported.formats":"\u5bfe\u5fdc\u5f62\u5f0f\uff1aJPEG\u3001PNG\u3001GIF\u3001BMP\u3002",//no i18n
"crm.imageupload.allowed.field.length":"\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u3067\u304d\u308b\u753b\u50cf\u306f{0}\u4ef6\u307e\u3067\u3067\u3059\u3002",//no i18n
"crm.general.crop.and.set":"\u5207\u308a\u53d6\u3063\u3066\u8a2d\u5b9a\u3059\u308b",//no i18n
"crm.attach.option.label":"\u6dfb\u4ed8\u3059\u308b",//no i18n
"crm.image.crop.and.rotate":"\u5207\u308a\u53d6\u308a\u3068\u56de\u8ee2",//no i18n
"crm.image.resolution":"\u89e3\u50cf\u5ea6",//no i18n
"crm.attachment.size":"\u30b5\u30a4\u30ba",//no i18n
"crm.label.close":"\u9589\u3058\u308b",//no i18n
"crm.label.previous":"\u524d\u3078",//no i18n
"crm.label.next":"\u6b21\u3078",//no i18n
"crm.subform.record.create.maxrow.limit": "{1}\u306e\u30c7\u30fc\u30bf\u6570\u306e\u4e0a\u9650\u306f{0}\u4ef6\u3067\u3059",
"crm.label.subform.addrows": "\u884c\u3092\u8ffd\u52a0\u3059\u308b",
"crm.tax.association.check": "\u3053\u306e\u5546\u54c1\u306b\u95a2\u9023\u4ed8\u3051\u3089\u308c\u3066\u3044\u308b\u7a0e\u91d1\u306f\u3042\u308a\u307e\u305b\u3093\u3002",
	"crm.gallery.inventory.template.discount": "\u5272\u5f15",
	"crm.inventory.discount.scheme.range": "\u7bc4\u56f2",
	"Description": "\u8a73\u7d30\u60c5\u5831",
	"crm.inventory.lineitem.no.pricebook": "\u95a2\u9023\u4ed8\u3051\u3089\u308c\u3066\u3044\u308b\u4fa1\u683c\u8868\u306f\u3042\u308a\u307e\u305b\u3093\u3002",
	"crm.recordImage.previewimage":"\u753b\u50cf\u306e\u30d7\u30ec\u30d3\u30e5\u30fc",//no i18n
"crm.FileuploadField.addNewImage":"\u753b\u50cf\u3092\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u3059\u308b",//no i18n
"crm.fileuploader.removefile":"\u524a\u9664\u3059\u308b",//no i18n
	"voc.gc.configure":"\u8a2d\u5b9a\u3059\u308b",//no i18n
"Edit":"\u7de8\u96c6",//no i18n
	"crm.criteria.secondaryfield.valid.check" : "\u300c{0}\u300d\u306b\u4e00\u81f4\u3059\u308b\u5024\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044",
	"crm.label.field":"\u9805\u76ee",//no i18n
"crm.label.value":"\u5024",//no i18n
	"crm.button.clone":"\u8907\u88fd\u3059\u308b",//no i18n
	"sentiment.criteria.wrongcriteria":"\u6761\u4ef6\u306e\u5024\u306f\u3001{0}\u4ee5\u4e0b\u306b\u3057\u3066\u304f\u3060\u3055\u3044\u3002",//no i18n
"crm.mb.field.common.splc":"\u7279\u6b8a\u6587\u5b57\u306f\u4f7f\u7528\u3067\u304d\u307e\u305b\u3093\u3002\u6709\u52b9\u306a\u5024\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044\u3002",//no i18n
	"crm.view.attachment.download":"\u30c0\u30a6\u30f3\u30ed\u30fc\u30c9\u3059\u308b",//no i18n
"crm.label.view":"\u8868\u793a\u3059\u308b",//no i18n
	"crm.label.field.plural":"\u9805\u76ee",//no i18n
	"crm.label.in.minutes":"{0}\uff08\u5206\uff09",//no i18n
	"crm.security.roles.list":"\u5f79\u8077\u306e\u4e00\u89a7",//no i18n
"crm.security.roles.lookup.info":"\u4e00\u89a7\u304b\u3089\u5f79\u8077\u3092\u9078\u629e\u3057\u3066\u304f\u3060\u3055\u3044\u3002",//no i18n
"crm.territory.addterritory":"\u30c6\u30ea\u30c8\u30ea\u30fc\u306e\u8ffd\u52a0",//no i18n
"crm.title.edit.territory":"\u30c6\u30ea\u30c8\u30ea\u30fc\u306e\u7de8\u96c6",//no i18n
"crm.territory.title.assign.territories":"\u30c6\u30ea\u30c8\u30ea\u30fc\u3092\u5272\u308a\u5f53\u3066\u308b",//no i18n
	"crm.label.context.help":"\u30d8\u30eb\u30d7",//no i18n
	"crm.label.from":"\u958b\u59cb",//no i18n
"crm.label.to":"\u7d42\u4e86",//no i18n
	"workflow.option.webhookFailure.fromDate":"\u958b\u59cb\u65e5",//no i18n
"workflow.option.webhookFailure.toDate":"\u7d42\u4e86\u65e5",//no i18n
"crm.custom.field.less.than.equalto":"{0}\u306f{1}\u4ee5\u4e0b\u306b\u3057\u3066\u304f\u3060\u3055\u3044\u3002",//no i18n
	"crm.template.listview.search.no.results":"\u8a72\u5f53\u3059\u308b\u9805\u76ee\u304c\u898b\u3064\u304b\u308a\u307e\u305b\u3093",//No I18n
	"crm.label.tag.new":"\u65b0\u3057\u3044\u30bf\u30b0",//No I18n
	"crm.wf.usage.date.criteria.error.msg":"\u7d42\u4e86\u65e5\u306f\u958b\u59cb\u65e5\u3088\u308a\u5f8c\u306b\u306a\u308b\u3088\u3046\u306b\u8a2d\u5b9a\u3057\u3066\u304f\u3060\u3055\u3044\u3002",//no i18n
	"crm.label.enter.tag":"\u30bf\u30b0\u3092\u5165\u529b",//No I18n
	"crm.label.enter.tag":"Enter Tags",//No I18n
	"Administrator":"\u7ba1\u7406\u8005",//No I18n
	"Standard":"\u6a19\u6e96",//No I18n
	"crux.comboBox.max.limit":"{0}\u4ef6\u4ee5\u4e0a\u306e{1}\u3092\u9078\u629e\u3059\u308b\u3053\u3068\u306f\u3067\u304d\u307e\u305b\u3093\u3002.",//NO I18n
	"crm.button.add":"\u8ffd\u52a0",//NO I18n
	"crm.label.users":"\u30e6\u30fc\u30b6\u30fc", //NO I18n
  "crm.workflow.alert.roles":"\u5f79\u8077", //NO I18n
  "crm.security.groups":"\u30b0\u30eb\u30fc\u30d7", //NO I18n
	"crm.label.available" : "\u9078\u629e\u53ef\u80fd\u306a\u9805\u76ee", //NO I18n
	"crm.label.assign.manually" : "\u5272\u308a\u5f53\u3066", //NO I18n
	"crm.globalsearch.option.all": "\u3059\u3079\u3066", //NO I18n
	"webform.status.Active":"\u6709\u52b9", //NO I18n
	"Inactive":"\u7121\u52b9", //NO I18n
  "Confirmed":"\u8a8d\u8a3c\u6e08\u307f", //NO I18n
  "crm.user.component.unconfirmed":"\u672a\u627f\u8a8d",//no i18n
  "DeletedUser":"\u524a\u9664\u6e08\u307f", //NO I18n
  "crm.feed.group.admin":"\u7ba1\u7406\u8005", //NO I18n
  "crm.ln.lable.current":"\u73fe\u5728", //NO I18n
	"crm.label.selected": "\u9078\u629e\u3057\u305f\u9805\u76ee",//NO I18n
	"crm.auditlog.user": "\u30e6\u30fc\u30b6\u30fc", //NO I18n
	"cob.role": "\u5f79\u8077", //NO I18n
	"zoho.email": "\u30e1\u30fc\u30eb", //NO I18n
	"Profile": "\u30d7\u30ed\u30d5\u30a3\u30fc\u30eb", //NO I18n
	"crm.security.group.users.empty": "\u30e6\u30fc\u30b6\u30fc\u306e\u30c7\u30fc\u30bf\u306f\u3042\u308a\u307e\u305b\u3093\u3002", //NO I18n
	"crm.label.picklist.none": "--\u306a\u3057--", //NO I18n
	"crm.usrpop.non.selected" : "\u9078\u629e\u3057\u305f\u30e6\u30fc\u30b6\u30fc",//NO I18n
	"crm.zti.label.user": "\u30e6\u30fc\u30b6\u30fc\u540d", //NO I18n
	"crm.label.notSelected" : "\u672a\u9078\u629e",//NO I18n
	"AM" : "\u5348\u524d",//NO I18n
	"Call" : "\u96fb\u8a71",//NO I18n
	"crm.phoneNo.Link.Title" : "VOIP\u3092\u4f7f\u7528\u3057\u3066\u767a\u4fe1",//NO I18n
	"crm.button.cancel" : "\u30ad\u30e3\u30f3\u30bb\u30eb",//NO I18n
	"crm.button.save" : "\u4fdd\u5b58",//NO I18n
	"crm.no.data.found" : "\u30c7\u30fc\u30bf\u304c\u898b\u3064\u304b\u308a\u307e\u305b\u3093\u3067\u3057\u305f",//NO I18n
	"crm.label.no.options.found" : "\u30aa\u30d7\u30b7\u30e7\u30f3\u306f\u3042\u308a\u307e\u305b\u3093",//No I18n
	"crm.globalsearch.search.title" : "\u691c\u7d22",//No I18n
	"None" : "\u306a\u3057",//No I18n
	"crm.label.criteria.pattern" : "\u6761\u4ef6\u30d1\u30bf\u30fc\u30f3",//No I18n
	"crm.label.edit.criteria.pattern" : "\u30d1\u30bf\u30fc\u30f3\u306e\u7de8\u96c6",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "\u6761\u4ef6\u306b\u4e00\u81f4\u3057\u307e\u305b\u3093\u3067\u3057\u305f -- \u6761\u4ef6\u3092\u78ba\u8a8d\u3057\u3066\u304f\u3060\u3055\u3044",//No I18n
	"criteria.error.alert.brackets.invalid" : "\u6761\u4ef6\u6f14\u7b97\u5b50\u304c\u7121\u52b9\u306a\u62ec\u5f27\u3067\u304f\u304f\u3089\u308c\u3066\u3044\u307e\u3059\u3002",//No I18n
	"crm.criteria.number.notmatch.check" : "{0}\u306b\u4e00\u81f4\u3057\u307e\u305b\u3093",//No I18n
	"criteria.error.alert.other.params" : "\u6761\u4ef6\u306b\u4e00\u81f4\u3057\u307e\u305b\u3093\u3067\u3057\u305f -- \u6761\u4ef6\u3092\u78ba\u8a8d\u3057\u3066\u304f\u3060\u3055\u3044", //No I18n
	"crm.label.search.for.users": "\u30e6\u30fc\u30b6\u30fc\u306e\u691c\u7d22", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "\u6761\u4ef6\u306b\u4e00\u81f4\u3057\u307e\u305b\u3093\u3067\u3057\u305f -- \u6761\u4ef6\u3092\u78ba\u8a8d\u3057\u3066\u304f\u3060\u3055\u3044", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "\u6761\u4ef6\u306b\u4e00\u81f4\u3057\u307e\u305b\u3093\u3067\u3057\u305f -- \u6761\u4ef6\u3092\u78ba\u8a8d\u3057\u3066\u304f\u3060\u3055\u3044", //No I18n
	"and" : "\u304b\u3064", //No I18n
	"or" : "\u307e\u305f\u306f", //No I18n
	"crm.label.or" : "\u307e\u305f\u306f", //No I18n
	"crm.label.and" : "\u304b\u3064", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "\u884c\uff08{0}\uff09\u306b\u6709\u52b9\u306a\u9805\u76ee\u306e\u30e9\u30d9\u30eb\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044", //No I18n
	"crm.criteria.condition.valid.check" : "\u9805\u76ee\uff08{0}\uff09\u306b\u6709\u52b9\u306a\u6761\u4ef6\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044", //No I18n
	"crm.field.valid.check" : "\u6709\u52b9\u306a{0}\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044\u3002", //No I18n
	"crm.custom.field.less.than.to" : "<i>\u300c\u304b\u3089\u300d</i>\u306f<i>\u300c\u307e\u3067\u300d</i>\u3088\u308a\u5c0f\u3055\u304f\u3057\u3066\u304f\u3060\u3055\u3044\u3002", //No I18n
	"crm.alert.label.savepattern" : "\u6761\u4ef6\u3092\u4fdd\u5b58\u3057\u3066\u304b\u3089\u5909\u66f4\u3057\u3066\u304f\u3060\u3055\u3044",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "\u6761\u4ef6\u3092\u8ffd\u52a0\u3067\u304d\u307e\u305b\u3093\u3002",//No I18n
	"is" : "\u6b21\u306e\u5024\u3068\u7b49\u3057\u3044",//No I18n
	"isn\'t" : "\u304c\u6b21\u306e\u5024\u3068\u7b49\u3057\u304f\u306a\u3044",//No I18n
	"contains" : "\u304c\u6b21\u306e\u5024\u3092\u542b\u3080",//No I18n
	"doesn\'t contain" : "\u304c\u6b21\u306e\u5024\u3092\u542b\u307e\u306a\u3044",//No I18n
	"starts with" : "\u306f\u6b21\u306e\u5024\u3067\u59cb\u307e\u308b",//No I18n
	"ends with" : "\u306f\u6b21\u306e\u5024\u3067\u7d42\u308f\u308b",//No I18n
	"is empty" : "\u304c\u7a7a\u3067\u3042\u308b",//No I18n
	"is not empty" : "\u304c\u7a7a\u3067\u306f\u306a\u3044",//No I18n
	"is before" : "\u306f\u6b21\u306e\u524d",//No I18n
	"is after" : "\u306f\u6b21\u306e\u5f8c",//No I18n
	"between" : "\u304c\u6b21\u306e\u9593\u306b\u3042\u308b",//No I18n
	"not between" : "\u304c\u6b21\u306e\u9593\u306b\u306a\u3044",//No I18n
	"Today" : "\u4eca\u65e5",//No I18n
	"Tommorow" : "\u660e\u65e5",//No I18n
	"Tommorow Onwards" : "\u660e\u65e5\u4ee5\u964d",//No I18n
	"Yesterday" : "\u6628\u65e5",//No I18n
	"Till Yesterday" : "\u6628\u65e5\u4ee5\u524d",//No I18n
	"Last Month" : "\u5148\u6708",//No I18n
	"Current Month" : "\u4eca\u6708", //No I18n
	"Next Month" : "\u6765\u6708", //No I18n
	"Last Week" : "\u5148\u9031", //No I18n
	"Current Week" : "\u4eca\u9031", //No I18n
	"Next Week" : "\u6765\u9031", //No I18n
	"Age in Days" : "\u7d4c\u904e\u65e5\u6570", //No I18n
	"Due in Days" : "\u671f\u9650\u307e\u3067\u306e\u65e5\u6570", //No I18n
	"Scheduled" : "\u4e88\u5b9a\u6e08\u307f", //No I18n
	"Attended Dialled" : "\u5fdc\u7b54\u304c\u3042\u3063\u305f\u767a\u4fe1", //No I18n
	"Unattended Dialled" : "\u5fdc\u7b54\u304c\u306a\u304b\u3063\u305f\u767a\u4fe1", //No I18n
	"Overdue" : "\u671f\u9650\u3092\u904e\u304e\u305f\u30bf\u30b9\u30af", //No I18n
	"Cancelled" : "\u30ad\u30e3\u30f3\u30bb\u30eb", //No I18n
	"Received" : "\u53d7\u4fe1", //No I18n
	"Missed" : "\u4e0d\u5728\u7740\u4fe1", //No I18n
	"crm.alert.character.not.allowed" : "{0}\u306f\u5229\u7528\u3067\u304d\u307e\u305b\u3093", //No I18n
	"crm.condition.in.last" : "\u904e\u53bb", //No I18n
	"crm.zinvoice.dueIn" : "\u671f\u9650\uff1a", //No I18n
	"on" : "\uff1a",//No I18n
	"before" : "\u524d",//No I18n
	"crm.label.general.small.after" : "\u6b21\u306e\u5f8c",//No I18n
	"crm.thisweek" : "\u4eca\u9031",//No I18n
	"crm.label.this.month" : "\u4eca\u6708",//No I18n
	"crm.thisyear" : "\u4eca\u5e74",//No I18n
	"crm.source.user.and.system" : "\u30e6\u30fc\u30b6\u30fc\u304a\u3088\u3073\u30b7\u30b9\u30c6\u30e0",//No I18n
	"crm.source.user.or.system" : "\u30e6\u30fc\u30b6\u30fc\u307e\u305f\u306f\u30b7\u30b9\u30c6\u30e0",//No I18n
	"crm.label.system2" : "\u30b7\u30b9\u30c6\u30e0",//No I18n
	"crm.source.user.only" : "\u30e6\u30fc\u30b6\u30fc\u306e\u307f",//No I18n
	"crm.source.system.only" : "\u30b7\u30b9\u30c6\u30e0\u306e\u307f",//No I18n
	"crm.condition.till.today" : "\u4eca\u65e5\u307e\u3067",//No I18n
	"game.month.left" : "1\u30ab\u6708",//No I18n
	"game.months.left" : "{0}\u30ab\u6708",//No I18n
	"crm.condition.last.30.days" : "\u904e\u53bb30\u65e5\u9593",//No I18n
	"crm.condition.last.60.days" : "\u904e\u53bb60\u65e5\u9593",//No I18n
	"crm.condition.last.90.days" : "\u904e\u53bb90\u65e5\u9593",//No I18n
	"crm.label.filter.typehere" : "\u3053\u3061\u3089\u306b\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044", //No I18N
	"crm.filter.is.not" : "\u304c\u6b21\u306e\u5024\u3068\u7b49\u3057\u304f\u306a\u3044", //No I18n
	"crm.condition.until.now" : "\u73fe\u5728\u307e\u3067",//No I18n
	"crm.filter.email.isblocked" : "\u30d6\u30ed\u30c3\u30af\u3055\u308c\u3066\u3044\u308b",//No I18n
	"crm.filter.email.isnotblocked" : "\u30d6\u30ed\u30c3\u30af\u3055\u308c\u3066\u3044\u306a\u3044",//No I18n
	"crm.label.no.results.match" : "\u8a72\u5f53\u3059\u308b\u9805\u76ee\u306f\u898b\u3064\u304b\u308a\u307e\u305b\u3093\u3067\u3057\u305f",//No I18n
	"crm.label.select.user" : "\u30e6\u30fc\u30b6\u30fc\u3092\u9078\u629e\u3059\u308b\u306b\u306f\u30af\u30ea\u30c3\u30af\u3057\u3066\u304f\u3060\u3055\u3044", //No I18n
	"current.logged.in.user": "\u30b5\u30a4\u30f3\u30a4\u30f3\u3057\u305f\u30e6\u30fc\u30b6\u30fc", //NO I18n
	"current.logged.in.user.definition": "\u30c7\u30fc\u30bf\u306e\u51e6\u7406\u3092\u5b9f\u884c\u3057\u305f\u30e6\u30fc\u30b6\u30fc", //NO i18n
	"crm.security.group": "\u30b0\u30eb\u30fc\u30d7", //NO I18n
	"crm.security.role": "\u5f79\u8077", //NO I18n
	"Date" : "\u65e5\u4ed8",//No I18n
	"crm.field.valid.decimal.check2" : "\u9805\u76ee\u300c{0}\u300d\u306e\u5c0f\u6570\u70b9\u4ee5\u4e0b\u306e\u6841\u6570\u306f{1}\u4ee5\u4e0b\u3067\u3042\u308b\u5fc5\u8981\u304c\u3042\u308a\u307e\u3059\u3002",//No I18n
	"crm.field.empty.check" : " {0}\u306f\u5165\u529b\u5fc5\u9808\u3067\u3059",//No I18n
	"crm.label.add.note": "\u30e1\u30e2\u306e\u8ffd\u52a0", //NO I18n
	"crm.label.simply.by": "\u5b9f\u884c\u8005", //NO I18n
	"crm.general.addnote": "\u30e1\u30e2\u306e\u8ffd\u52a0", //NO I18n
	"crm.general.addtitle": "\u4ef6\u540d\u306e\u8ffd\u52a0", //NO I18n
	"crm.label.attach.file": "\u6dfb\u4ed8\u30d5\u30a1\u30a4\u30eb", //NO I18N
	"crm.button.mass.delete": "\u524a\u9664", //NO I18N
	"crm.warning.delete.record": "\u300c{0}\u300d\u3092\u3054\u307f\u7bb1\u306b\u79fb\u52d5\u3057\u307e\u3059\u304b\uff1f", //NO I18N
	"crm.label.yes": "\u306f\u3044", //NO I18N
	"crm.note.view.previous": "\u4ee5\u524d\u306e\u30e1\u30e2\u3092\u8868\u793a", //NO I18N
  "of": "\uff1a", //NO I18N
	"crm.label.notes": "\u30e1\u30e2", //NO I18N
	"crm.note.recent.first": "\u65b0\u3057\u3044\u9806", //NO I18N
	"crm.note.recent.last": "\u53e4\u3044\u9806", //NO I18N
	"crm.territory.label.only": "{0} \u306e\u307f", //no i18n
	"crm.select" : "\u9078\u629e",//No I18n
	"crm.button.apply.filter" : "\u30d5\u30a3\u30eb\u30bf\u30fc\u306e\u9069\u7528",//No I18n
	"crm.alert.maximum.text.values.contains" : "\u3053\u306e\u9805\u76ee{0}\u3092\u8d85\u3048\u308b\u5024\u3092\u5165\u529b\u3059\u308b\u3053\u3068\u306f\u3067\u304d\u307e\u305b\u3093\u3002",//No I18n
	"PM" : "\u5348\u5f8c",//No I18n
	//start-cx create form keys
	"crm.duplicate.value.not.allowed": "\u5024\u306e\u91cd\u8907\u304c\u7981\u6b62\u3055\u308c\u3066\u3044\u307e\u3059\u3002",//no i18n
	"crm.duplicate.value.available": "\u540c\u3058{1}\u304c\u767b\u9332\u3055\u308c\u3066\u3044\u308b{0}\u304c\u898b\u3064\u304b\u308a\u307e\u3057\u305f\u3002",//no i18n
	"crm.duplicate.value.available.multiple": "\u540c\u3058{1}\u304c\u767b\u9332\u3055\u308c\u3066\u3044\u308b{0}\u304c\u8907\u6570\u898b\u3064\u304b\u308a\u307e\u3057\u305f\u3002",//no i18n
	"crm.custombutton.nobuttons.found": "\u30dc\u30bf\u30f3\u304c\u898b\u3064\u304b\u308a\u307e\u305b\u3093",//no i18n
	"crm.custombutton.create.newbutton": "\u30dc\u30bf\u30f3\u3092\u4f5c\u6210\u3059\u308b",//no i18n
	"crm.custombutton.manage.button": "\u30dc\u30bf\u30f3\u3092\u7ba1\u7406\u3059\u308b",//no i18n
	"crm.custombutton.name": "\u540d\u524d",//no i18n
	"crm.customize.custombutton.function.desc": "\u8a73\u7d30\u60c5\u5831",//no i18n
	"crm.custombutton.action": "\u30dc\u30bf\u30f3\u306e\u51e6\u7406\u5185\u5bb9",//no i18n
	"crm.custombutton.empty.field.values": "\u4ee5\u4e0b\u306e\u9805\u76ee\u306e\u5024\u304c\u7a7a\u3067\u3059\uff1a",//no i18n
	"crm.custombutton.proceed.action": "\u672c\u5f53\u306b\u7d9a\u3051\u307e\u3059\u304b\uff1f",//no i18n
	"zb.common.Cancel": "\u30ad\u30e3\u30f3\u30bb\u30eb\u3059\u308b",//no i18n
	"crm.yes.proceed": "\u7d9a\u3051\u308b",//no i18n
	"crm.label.module.merge": "{0}\u3092\u7d71\u5408\u3059\u308b",//no i18n
	"crm.view.record": "{0}\u3092\u8868\u793a\u3059\u308b",//no i18n
	"crm.tpi.ctiapi.config.choosefile": "\u30d5\u30a1\u30a4\u30eb\u3092\u9078\u629e",//no i18n
	"crm.label.subform.addrows": "\u884c\u3092\u8ffd\u52a0\u3059\u308b",//no i18n
	"crm.button.save&new": "\u4fdd\u5b58\u3057\u3066\u65b0\u3057\u304f\u4f5c\u6210\u3059\u308b",//no i18n
	//end-cx create form keys
	"crm.mb.newversion.msg4" : "\u306f\u3044\u3001\u4e86\u89e3\u3057\u307e\u3057\u305f",//No I18n
	"Apr" : "4\u6708",//No I18n
	"Aug" : "8\u6708",//No I18n
	"Jan" : "1\u6708",//No I18n
	"Jul" : "7\u6708",//No I18n
	"Jun" : "6\u6708",//No I18n
	"Mar" : "3\u6708",//No I18n
	"Nov" : "11\u6708",//No I18n
	"Oct" : "10\u6708",//No I18n
	"Feb" : "2\u6708",//No I18n
	"May" : "5\u6708",//No I18n
	"Sep" : "9\u6708",//No I18n
	"Dec" : "12\u6708",//No I18n
	"crm.label.More" :"\u305d\u306e\u4ed6", //no i18n

	"crm.label.unmapped.stages":"\u30a2\u30ab\u30a6\u30f3\u30c8\u306b\u672a\u8a2d\u5b9a", //no i18n
	"crm.wf.summary.label.ShowInstantActions" : "\u8a73\u7d30\u8868\u793a",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "\u7c21\u6613\u8868\u793a",//No I18n

	 	 //filter related keys-start
"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
"crm.lead.prediction.tooltip.convert":"\u5909\u63db",//no i18n
"crm.lead.prediction.tooltip.score":"\u5f97\u70b9",//no i18n
"Planned":"\u4e88\u5b9a",//no i18n
"Invited":"\u62db\u5f85\u6e08\u307f",//no i18n
"Sent":"\u9001\u4fe1",//no i18n
"Received":"\u53d7\u4fe1",//no i18n
"Opened":"\u958b\u5c01",//no i18n
"Responded":"\u8fd4\u4fe1\u3042\u308a",//no i18n
"Bounced":"\u4e0d\u7740",//no i18n
"Opted\ Out":"\u81ea\u52d5\u30e1\u30fc\u30eb\u9001\u4fe1\u5bfe\u8c61\u304b\u3089\u9664\u5916\uff08\u30aa\u30d7\u30c8\u30a2\u30a6\u30c8\uff09",//no i18n
"crm.filter.label.with.open":"\u672a\u5b8c\u4e86\u306e{0}\u304c\u3042\u308b",//no i18n
"crm.filter.label.without.open":"\u672a\u5b8c\u4e86\u306e{0}\u304c\u306a\u3044",//no i18n
"crm.filter.label.without.any":"{0}\u304c\u306a\u3044",//no i18n
"crm.filter.label.with.module":"{0}\u3068\u4e00\u7dd2\u306b",//no i18n
"crm.filter.label.activity.due":"{0}\u306e\u671f\u9650",//no i18n
"crm.filter.label.activity.done":"{0}\u306e\u5b8c\u4e86",//no i18n
"Notes":"\u30e1\u30e2",//no i18n
"crm.filter.label.notes.added":"\u30e1\u30e2\u3092\u8ffd\u52a0\u3057\u307e\u3057\u305f",//no i18n
"crm.label.filter.email.status":"\u30e1\u30fc\u30eb\u306e\u72b6\u6cc1",//no i18n
"crm.label.filter.email.clicked":"\u30af\u30ea\u30c3\u30af\u6e08\u307f", //no i18n
"crm.label.filter.email.responded":"\u8fd4\u4fe1\u6e08\u307f", //no i18n
"crm.label.filter.email.info":"\u9001\u53d7\u4fe1\u30e1\u30fc\u30eb\u306e\u6700\u65b0\u30b9\u30c6\u30fc\u30bf\u30b9\u306b\u57fa\u3065\u3044\u3066\u30c7\u30fc\u30bf\u3092\u62bd\u51fa\u3067\u304d\u307e\u3059\u3002",// no i18n
"crm.filter.label.sent":"\u9001\u4fe1",//no i18n
"crm.filter.label.not.sent":"\u672a\u9001\u4fe1",//no i18n
"crm.filter.label.opened":"\u958b\u5c01",//no i18n
"crm.filter.label.not.opened":"\u672a\u958b\u5c01",//no i18n
"crm.filter.label.received":"\u53d7\u4fe1",//no i18n
"crm.filter.label.not.received":"\u672a\u5230\u9054",//no i18n
"crm.filter.label.bounced":"\u4e0d\u9054",//no i18n
"crm.filter.label.opened.not.replied":"\u958b\u5c01\uff06\u672a\u8fd4\u4fe1", //no i18n
"crm.filter.label.any":"\u4e0a\u8a18\u306e\u3044\u305a\u308c\u304b",//no i18n
"crm.zia.config.potential.amount":"{0}\u4ef6",//no i18n
"Quote\ Stage":"{0}\u30b9\u30c6\u30fc\u30b8",//no i18n
"crm.module.owner":"{0}\u306e\u62c5\u5f53\u8005",//no i18n
"Potential\ Closing\ Date":"{0}\u5b8c\u4e86\u4e88\u5b9a\u65e5",//no i18n
"crm.lead.prediction.likely.convert":"\u30b3\u30f3\u30d0\u30fc\u30c8\u898b\u8fbc\u307f\u304c\u9ad8\u3044",//no i18n
"crm.lead.prediction.convert.high":"\u9ad8",//no i18n
"crm.lead.prediction.convert.medium":"\u4e2d",//no i18n
"crm.lead.prediction.convert.low":"\u4f4e",//no i18n
"crm.predictions.feature.label":"\u4e88\u6e2c",//no i18n
"crm.intelligence.prediction.likelywin":"\u53d7\u6ce8\u3059\u308b\u53ef\u80fd\u6027\u304c\u9ad8\u3044",//no i18n
"crm.intelligence.prediction.likelylose":"\u5931\u6ce8\u3059\u308b\u53ef\u80fd\u6027\u304c\u9ad8\u3044",//no i18n
"crm.intelligence.prediction.halfchance":"\u4e94\u5206\u4e94\u5206\u306e\u78ba\u7acb",//no i18n
"crm.intelligence.prediction.score":"\u4e88\u6e2c\u30b9\u30b3\u30a2",//no i18n
"crm.lead.prediction.recent.score":"\u6700\u65b0\u306e\u4e88\u6e2c\u70b9",//no i18n
"crm.intelligence.prediction.lastconv":"\u76f4\u8fd1\u306e3\u4ef6\u306e\u3084\u308a\u3068\u308a",//no i18n
"crm.intelligence.prediction.recordsfocus":"\u6ce8\u76ee\u3059\u308b\u30c7\u30fc\u30bf",//no i18n
"crm.intelligence.prediction.slowmoving":"\u4f4e\u901f\u3067\u79fb\u52d5",//no i18n
"crm.intelligence.prediction.trend.down":"\u6700\u8fd1\u4e0b\u964d\u6c17\u5473\u306e\u30c8\u30ec\u30f3\u30c9",//no i18n
"crm.label.touched.records":"\u66f4\u65b0\u304c\u3042\u308b\u30c7\u30fc\u30bf\uff08\u95a2\u9023\u30c7\u30fc\u30bf\u3082\u542b\u3080\uff09",//no i18n
"crm.label.untouched.records":"\u66f4\u65b0\u304c\u306a\u3044\u30c7\u30fc\u30bf\uff08\u95a2\u9023\u30c7\u30fc\u30bf\u3082\u542b\u3080\uff09",//no i18n
"crm.label.record.action":"\u8a72\u5f53\u30c7\u30fc\u30bf\u306e\u66f4\u65b0\u6709\u7121",//no i18n
"workflow.rule.view.label.Modified":"\u66f4\u65b0\u3042\u308a",//no i18n
"crm.label.related.records.action":"\u95a2\u9023\u30c7\u30fc\u30bf\u306e\u66f4\u65b0\u6709\u7121",//no i18n
"crm.label.not.modified":"\u66f4\u65b0\u306a\u3057",//no i18n
"crm.label.related.records.action":"\u95a2\u9023\u30c7\u30fc\u30bf\u306e\u66f4\u65b0\u6709\u7121",//no i18n
"Done":"\u66f4\u65b0\u3042\u308a",//no i18n
"crm.label.not.done":"\u66f4\u65b0\u306a\u3057",//no i18n
"sentiment.model":"\u30e1\u30fc\u30eb\u306e\u6e29\u5ea6\u611f",//no i18n
"sentiment.criteria.count":"\u4ef6\u6570",//no i18n
"sentiment.criteria.percentage":"\u30d1\u30fc\u30bb\u30f3\u30c8\uff08\uff05\uff09",//no i18n
"sentiment.criteria.lastmail":"\u6700\u5f8c\u306e\u30e1\u30fc\u30eb",//no i18n
"Chats":"\u30c1\u30e3\u30c3\u30c8",//no i18n
"Attended":"\u5bfe\u5fdc\u6e08\u307f",//no i18n
"crm.lead.prediction.popup.text":"\u5909\u63db\u3059\u308b\u53ef\u80fd\u6027\u304c\u9ad8\u3044\u5834\u5408\u306f",//no i18n
"crm.lead.prediction.popup.final":"\u30d5\u30a3\u30eb\u30bf\u30fc\u3092\u5909\u66f4\u3057\u3066\u3001\u3082\u3046\u4e00\u5ea6\u8a66\u3057\u3066\u304f\u3060\u3055\u3044\u3002",//no i18n
"crm.custom.field.less.than.to1":"\u300c\u304b\u3089\u300d\u306f\u300c\u307e\u3067\u300d\u4ee5\u4e0b\u306b\u3057\u3066\u304f\u3060\u3055\u3044\u3002",//no i18n
"Last\ Activity\ Date":"\u6700\u5f8c\u306e\u5229\u7528\u65e5",//no i18n
"crm.label.vendor.name":"{0}\u540d",//no i18n
"hours":"\u6642\u9593",//no i18n
"days":"\u65e5\u9593",//no i18n
"weeks":"\u9031\u9593",//no i18n
"months":"\u30ab\u6708",//no i18n
"years":"\u5e74",//no i18n
"crm.label.general.small.after":"\u6b21\u306e\u5f8c",//no i18n
"Last\ Week":" \u5148\u9031",//no i18n
"Last\ Month":" \u5148\u6708",//no i18n
"crm.module.name":"{0}\u540d",//no i18n
"Campaign":"\u30ad\u30e3\u30f3\u30da\u30fc\u30f3",//no i18n
"Tasks":"\u30bf\u30b9\u30af",//no i18n
"Calls":"\u901a\u8a71",//no i18n
"Events":"\u4e88\u5b9a",//no i18n
"sentiment.criteria.wrongcriteria":"\u57fa\u6e96\u5024\u306f{0}\u4ee5\u4e0b\u306b\u3057\u3066\u304f\u3060\u3055\u3044\u3002",//no i18n
"crm.chosen.minimum.input.text":"{0}\u4ee5\u4e0a\u306e\u6587\u5b57\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044",//no i18n
"crm.intelligence.prediction.trendup":"\u30c8\u30ec\u30f3\u30c9\u30a2\u30c3\u30d7",//no i18n
"crm.intelligence.prediction.trenddown":"\u30c8\u30ec\u30f3\u30c9\u30c0\u30a6\u30f3",//no i18n
"crm.zia.prediction.notrend":"\u50be\u5411\u304c\u3042\u308a\u307e\u305b\u3093",//no i18n
"crm.zia.prediction.likelihood":"\u898b\u8fbc\u307f\u30b9\u30b3\u30a2",//no i18n
"Completed":"\u5b8c\u4e86" ,//no i18n
"crm.label.success":"\u6210\u529f" ,//no i18n
"crm.label.Failure":"\u6b21\u306f\u4f55\u3067\u3059\u304b\uff1f" ,//no i18n
"Both":"\u4e21\u65b9" ,//no i18n
"crm.condition.cannot.empty":"\u6761\u4ef6\u306f\u5165\u529b\u5fc5\u9808\u3067\u3059\u3002",//no i18n
"crm.sentiment.Positive":"\u826f\u3044",//no i18n
"crm.sentiment.Negative":"\u60aa\u3044",//no i18n
"sentiment.positiveandnegative":"\u30dd\u30b8\u30c6\u30a3\u30d6\u3068\u30cd\u30ac\u30c6\u30a3\u30d6",//no i18n
"sentiment.positiveornegative":"\u30dd\u30b8\u30c6\u30a3\u30d6\u307e\u305f\u306f\u30cd\u30ac\u30c6\u30a3\u30d6",//no i18n
"sentiment.positiveonly":"\u30dd\u30b8\u30c6\u30a3\u30d6\u306e\u307f",//no i18n
"sentiment.negativeonly":"\u30cd\u30ac\u30c6\u30a3\u30d6\u306e\u307f",//no i18n
"crm.sentiment.Neutral":"\u307e\u3042\u307e\u3042",//no i18n
"crm.filters.select.campaign.type":"{0}\u306e\u7a2e\u985e\u306e\u9078\u629e",//no i18n
"crm.filters.select.campaign.status":"{0}\u306e\u30b9\u30c6\u30fc\u30bf\u30b9\u306e\u9078\u629e",//no i18n
"campaign.Member" : "\u30e1\u30f3\u30d0\u30fc",//no i18n
	"Service":"\u30b5\u30fc\u30d3\u30b9",//no i18n
"Activities":"\u6d3b\u52d5",//no i18n
"crm.livedesk.pot.nextdays":"\u4eca\u5f8c{0}\u65e5\u9593",//no i18n
"Today\ +\ Overdue":"\u4eca\u65e5\u306e\u30bf\u30b9\u30af\u3068\u671f\u9650\u3092\u904e\u304e\u305f\u30bf\u30b9\u30af",//no i18n
"User":"\u30e6\u30fc\u30b6\u30fc",//no i18n
"crm.source.user.only":"\u30e6\u30fc\u30b6\u30fc\u306e\u307f",//no i18n
"crm.source.system.only":"\u30b7\u30b9\u30c6\u30e0\u306e\u307f",//no i18n
"Scheduled":"\u4e88\u5b9a\u6e08\u307f",//no i18n
"Attended\ Dialled":"\u5fdc\u7b54\u304c\u3042\u3063\u305f\u767a\u4fe1",//no i18n
"Unattended\ Dialled":"\u5fdc\u7b54\u304c\u306a\u304b\u3063\u305f\u767a\u4fe1",//no i18n
"Cancelled":"\u30ad\u30e3\u30f3\u30bb\u30eb",//no i18n
"condition.till.now":"\u73fe\u6642\u70b9\u307e\u3067",//no i18n
"crm.recurring.no.months":"{0}\u30ab\u6708",//no i18n
"crm.lead.prediction.tooltip":"\u5909\u63db\u898b\u8fbc\u307f - \u30b9\u30b3\u30a2\u7bc4\u56f2",//no i18n
"crm.website.activity":"Web\u30b5\u30a4\u30c8\u306e\u6d3b\u52d5",//no i18n
"crm.label.By":"\u5b9f\u884c\u8005",//no i18n
"crm.chosen.searching.text":"\u691c\u7d22\u4e2d\u2026",//no i18n
"crm.label.memberstatus.is":"\u30e1\u30f3\u30d0\u30fc\u306e\u30b9\u30c6\u30fc\u30bf\u30b9\u306f",//no i18n
"crm.events.duration":"\u671f\u9593",//no i18n
"crm.title.clear.name":"\u30af\u30ea\u30a2",//no i18n
"crm.label.status.is":"\u30b9\u30c6\u30fc\u30bf\u30b9\uff1a",//no i18n
"zia.last3.help":"\u4f1a\u8a71\u306b\u306f\u3001\u901a\u8a71\u3001\u30bf\u30b9\u30af\u3001\u4e88\u5b9a\u3001\u53d7\u4fe1\u30e1\u30fc\u30eb\u3001\u30e1\u30e2\u3001\u8a2a\u554f\u3001\u30bd\u30fc\u30b7\u30e3\u30eb\u306e\u30b3\u30e1\u30f3\u30c8\u3001Desk\u7d4c\u7531\u306e\u554f\u3044\u5408\u308f\u305b\u304c\u542b\u307e\u308c\u307e\u3059\u3002",//no i18n
"crm.label.tag.related.to":"\u95a2\u9023\u30c7\u30fc\u30bf",//no i18n

	//filter related keys-End
	"crm.label.account.created" : "\u65b0\u3057\u3044{0}\u304c\u4f5c\u6210\u3055\u308c\u307e\u3059\u3002",//No I18n
	"crm.krp.no.records.found" : "{0}\u306f\u3042\u308a\u307e\u305b\u3093\u3002",//No I18n
	"crm.module.new" : "\u65b0\u3057\u3044{0}",//No I18n
	"crm.label.view" : "\u8868\u793a",//No I18n
	"crm.nsocial.customers" : "\u9867\u5ba2",//No I18n
	"crm.nsocial.open.potential" : "\u672a\u5b8c\u4e86\u306e{0}",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "\u305d\u306e\u4ed6",//No i18n
	"crm.field.length.check" : "{0}\u306e\u5024\u304c\u4e0a\u9650\u3092\u8d85\u3048\u3066\u3044\u307e\u3059", //No I18n
	"crm.lower.now":"\u4eca",//no i18n
	"crm.time.min.ago":"{0}\u5206\u524d",//no i18n
	"crm.time.mins.ago":"{0}\u5206\u524d",//no i18n
	"crm.time.hr.ago":"{0}\u6642\u9593\u524d",//no i18n
	"crm.time.hrs.ago":"{0}\u6642\u9593\u524d", //no i18n
	"AllUsers": "\u3059\u3079\u3066\u306e\u30e6\u30fc\u30b6\u30fc", //no i18n
	"crm.label.search":"\u691c\u7d22",//no i18n
	"crm.api.filterby":"\u30d5\u30a3\u30eb\u30bf\u30fc\uff1a",//no i18n
	"crm.customview.nofields.found":"--\u95a2\u9023\u3059\u308b\u9805\u76ee\u306a\u3057--",//no i18n
	"crm.setup.system.ziarecommendation":"Zia\u63d0\u6848",//no i18n
	"crm.setup.system.ziasimilarity":"\u985e\u4f3c\u30c7\u30fc\u30bf\u306e\u30ec\u30b3\u30e1\u30f3\u30c9",//no i18n
	"zia.similarity.smartfilter.score":"\u985e\u4f3c\u30b9\u30b3\u30a2",//no i18n
	"zia.similarity.smartfilter.records":"\u985e\u4f3c\u3057\u305f{0}",//no i18n
	"zia.similarity.smartfilter.records.search":"\u985e\u4f3c\u3057\u305f{0}\u3092\u8868\u793a\uff1a",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"{0}\u4ef6\u3092\u8d85\u3048\u308b{1}\u3092\u9078\u629e\u3059\u308b\u3053\u3068\u306f\u3067\u304d\u307e\u305b\u3093",//no i18n
	"crm.gdpr.notavailable.field":"\u4f7f\u7528\u3067\u304d\u306a\u3044\u9805\u76ee",//no i18n
	"crm.filter.label.all.products":"\u3059\u3079\u3066\u306e{0}",//no i18n
	"crm.filter.label.select.products":"\u9078\u629e\u4e2d\u306e{0}",//no i18n
	"crm.reviewprocess.smart.filter":"\u30ec\u30d3\u30e5\u30fc\u30d7\u30ed\u30bb\u30b9\u306e\u30c7\u30fc\u30bf\u306e\u30b9\u30c6\u30fc\u30bf\u30b9",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"\u5024\u3092\u9078\u629e\u3057\u3066\u304f\u3060\u3055\u3044\u3002",//no i18n
	"crm.segmentation.segment.score":"\u30bb\u30b0\u30e1\u30f3\u30c8\u30b9\u30b3\u30a2",//no i18n
	"crm.filter.label.in":"\u57fa\u6e96\uff1a",//no i18n
	"crm.filter.label.and.purchase.in":"\u3068\u3001\u6b21\u3067\u8cfc\u5165\u3059\u308b\u53ef\u80fd\u6027\u304c\u9ad8\u3044\uff1a",//no i18n
	"crm.filter.label.last.purchased":"\u3068\u6700\u8fd1\u7372\u5f97\u3057\u305f",//no i18n
	"crm.filter.label.a.day":"1\u65e5",//no i18n
	"crm.filter.label.a.week":"1\u9031\u9593",//no i18n
	"crm.filter.label.a.month":"1\u304b\u6708\u9593",//no i18n
	"crm.cal.custom":"\u30ab\u30b9\u30bf\u30e0",//no i18n
	"crm.mb.field.common.empt":"\u5024\u306f\u5165\u529b\u5fc5\u9808\u3067\u3059\u3002",//no i18n
	"crm.chosen.error.loading.text":"\u7d50\u679c\u306e\u51fa\u529b\u4e2d\u306b\u30a8\u30e9\u30fc\u304c\u767a\u751f\u3057\u307e\u3057\u305f",//no i18n
	"crm.filter.label.firstbuy":"\u521d\u56de",//no i18n
	"crm.filter.label.cwbab":"\u4f9d\u5b58",//no i18n
	"crm.filter.label.fbt":"\u30bb\u30c3\u30c8",//no i18n
	"crm.filter.label.rebuy":"\u7e70\u308a\u8fd4\u3057",//no i18n
	"crm.filter.label.nextbuy":"\u9023\u756a",//no i18n
	"crm.mxnlookup.select" : "{0}\u306e\u9078\u629e",//No I18n
	"crm.lookup.chooserecord":"{0}\u306e\u9078\u629e",//no i18n
	"crm.record.selected":"\u9078\u629e\u4e2d\u306e{0}",//no i18n
	"crm.module.empty.message" : "{0}\u306f\u3042\u308a\u307e\u305b\u3093\u3002",//No I18n
	"crm.mxnlookup.selected" : "\u9078\u629e\u4e2d\u306e{0}",//No I18n
	"crm.security.error" : "\u3053\u306e\u64cd\u4f5c\u3092\u884c\u3046\u6a29\u9650\u304c\u3042\u308a\u307e\u305b\u3093\u3002 \u7ba1\u7406\u8005\u30e6\u30fc\u30b6\u30fc\u306b\u304a\u554f\u3044\u5408\u308f\u305b\u304f\u3060\u3055\u3044\u3002", //No I18n
	"crm.label.creator.noPermission" : "\u6a29\u9650\u304c\u3042\u308a\u307e\u305b\u3093", //No I18n
	"crm.segmentation.frequency" : "\u983b\u5ea6", //No I18n
	"crm.segmentation.recency" : "\u6700\u65b0\u8cfc\u5165\u65e5", //No I18n
	"crm.segmentation.monetary" : "\u8cfc\u5165\u91d1\u984d", //No I18n
	"crm.smartfilter.related.module.msg" : "\u95a2\u9023\u30bf\u30d6\u306f\u3001\u6700\u59273\u4ef6\u307e\u3067\u9078\u629e\u3067\u304d\u307e\u3059\u3002" , //No I18n
	"crm.smartfilter.related.module.msg1" : "\uff08\u4f8b\uff1a\u30e1\u30fc\u30eb\u3001\u6d3b\u52d5\u3001\u30e1\u30e2\uff09", //No I18n
	"crm.smartfilter.related.module.msg2" : "\u671f\u9593\u306f\u5165\u529b\u5fc5\u9808\u3067\u3059", //No I18n
	"crm.label.timeZone": "\u30bf\u30a4\u30e0\u30be\u30fc\u30f3", //NO I18n
	"crm.label.insufficient.privileges": "\u3053\u306e\u51e6\u7406\u3092\u5b9f\u884c\u3059\u308b\u6a29\u9650\u304c\u3042\u308a\u307e\u305b\u3093\u3002\u7d44\u7e54\u306e\u7ba1\u7406\u8005\u306b\u9023\u7d61\u3057\u3066\u304f\u3060\u3055\u3044\u3002", //NO I18n
	"crm.filter.header.secton.system": "\u6a19\u6e96\u306e\u30d5\u30a3\u30eb\u30bf\u30fc", //NO I18N
	"crm.filter.header.secton.fields": "\u9805\u76ee\u5225\u30d5\u30a3\u30eb\u30bf\u30fc", //NO I18N
	"crm.createfield.calcinfo.new" : "\u3053\u306e\u9805\u76ee\u306f\u3001\u5165\u529b\u3057\u305f\u6570\u5f0f\u3092\u81ea\u52d5\u8a08\u7b97\u3057\u3066\u7d50\u679c\u3092\u8868\u793a\u3057\u307e\u3059\u3002</br><b>\u4f8b\uff1a20+20</b>\u3068\u5165\u529b\u3059\u308b\u3068\u3001\u81ea\u52d5\u3067<b>40</b>\u3068\u8868\u793a\u3055\u308c\u307e\u3059",//No i18n
	"crm.lable.read.only" : "\u8aad\u307f\u53d6\u308a\u5c02\u7528\u9805\u76ee",//No i18n
	"crm.column.sort.asc" : "\u6607\u9806",//No I18n
	"crm.column.sort.desc" : "\u964d\u9806",//No i18n
	"crm.column.unsort" : "\u4e26\u3079\u66ff\u3048\u3092\u89e3\u9664\u3059\u308b",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "\u9867\u5ba2\u306b\u5171\u6709", //NO I18N
	"crm.label.edited": "\u7de8\u96c6\u6e08\u307f", //NO I18N
	"crm.label.edited.on": "\u7de8\u96c6\u65e5", //NO I18N
	"crm.message.limit.exceed": "{1}\u306e\u6700\u5927\u6587\u5b57\u6570\u306f{0}\u6587\u5b57\u3067\u3059\u3002", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "\u9867\u5ba2\u3068\u5171\u6709", //NO I18N
	"crm.button.ok" : "OK", //NO I18N
	"crm.role.already.selected" : "\u3053\u306e\u5f79\u5272\u306f\u65e2\u306b\u9078\u629e\u3055\u308c\u3066\u3044\u307e\u3059", //no i18n
	"crm.user.deleted": "\u30e6\u30fc\u30b6\u30fc\u3092\u524a\u9664\u3057\u307e\u3057\u305f",  //NO I18N
	"crm.account.closed": "\u3053\u306e\u30a2\u30ab\u30a6\u30f3\u30c8\u306f\u9589\u9396\u3055\u308c\u307e\u3057\u305f",  //NO I18N
	"crm.start.chat": "\u30c1\u30e3\u30c3\u30c8\u3092\u958b\u59cb",  //NO I18N
	"crm.start.call": "\u901a\u8a71\u3092\u958b\u59cb",  //NO I18N
	"crm.recipient.invalid.email" : "\u7121\u52b9\u306a\u30e1\u30fc\u30eb\u304c\u898b\u3064\u304b\u308a\u307e\u3057\u305f\u3002", //NO I18N
	"crm.recipient.add.recipient" : "受信者を追加する", //NO I18N 
	"crm.start.video.call": "\u52d5\u753b\u30b3\u30fc\u30eb\u3092\u958b\u59cb",  //NO I18N //ignorei18n_start

	"crm.label.scoring.rules":"\u30b9\u30b3\u30a2\u30ea\u30f3\u30b0\u30eb\u30fc\u30eb",
	"Negative Score":"\u6e1b\u70b9",
	"Negative Touch Point Score":"\u30bf\u30c3\u30c1\u30dd\u30a4\u30f3\u30c8\u306e\u6e1b\u70b9",
	"Positive Score":"\u52a0\u70b9",
	"Positive Touch Point Score":"\u30bf\u30c3\u30c1\u30dd\u30a4\u30f3\u30c8\u306e\u52a0\u70b9",
	"Score":"\u30b9\u30b3\u30a2",
	"Touch Point Score":"\u30bf\u30c3\u30c1\u30dd\u30a4\u30f3\u30c8\u306e\u30b9\u30b3\u30a2",

	"is OPEN":"\u304c\u672a\u5b8c\u4e86",//no i18n
	"is WON":"\u304c\u53d7\u6ce8\u6e08\u307f",//no i18n
	"is LOST":"\u304c\u5931\u6ce8\u6e08\u307f",//no i18n
	"crm.potential.all.open":"\u3059\u3079\u3066\u306e\u672a\u5b8c\u4e86\u306e\u30b9\u30c6\u30fc\u30b8",//no i18n
	"crm.potential.all.won":"\u3059\u3079\u3066\u306e\u53d7\u6ce8\u306e\u30b9\u30c6\u30fc\u30b8",//no i18n
	"crm.potential.all.lost":"\u3059\u3079\u3066\u306e\u5931\u6ce8\u306e\u30b9\u30c6\u30fc\u30b8",//no i18n
	"crm.label.type.minutes": "\u3053\u3061\u3089\u306b\u5206\u5358\u4f4d\u3067\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044", //NO I18N

	"crm.campaign.member.status" : "\u53c2\u52a0\u30b9\u30c6\u30fc\u30bf\u30b9",//no i18n
	"crm.dashboard.select.type" : "{0}\u306e\u9078\u629e",//no i18n
	"crm.campaign.service.status":"\u30b5\u30fc\u30d3\u30b9\u72b6\u6cc1",//no i18n

	"crm.label.addColumn":"\u5217\u3092\u8ffd\u52a0\u3059\u308b",//no i18n
	"crm.button.clear.filter":"\u30d5\u30a3\u30eb\u30bf\u30fc\u3092\u9589\u3058\u308b",//no i18n
	"crm.button.show.filter":"\u30d5\u30a3\u30eb\u30bf\u30fc\u3092\u8868\u793a\u3059\u308b",//no i18n
	"crm.las.error.user.maxlimit":"\u9078\u629e\u3067\u304d\u308b\u30e6\u30fc\u30b6\u30fc\u306f20\u4eba\u307e\u3067\u3067\u3059\u3002",//no i18n
	"crm.las.error.picklist.maxlimit":"\u9078\u629e\u3067\u304d\u308b\u5024\u306f20\u4ef6\u307e\u3067\u3067\u3059\u3002",//no i18n

	"crm.fileuploader.message.responseerror": "\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u3067\u304d\u307e\u305b\u3093\u3067\u3057\u305f", //NO I18N
	"crm.storage.create.error":"\u3042\u306a\u305f\u306e\u30c7\u30fc\u30bf\u5bb9\u91cf\u304c\u4e0a\u9650\u306b\u9054\u3057\u305f\u305f\u3081\u3001\u65b0\u3057\u3044\u30c7\u30fc\u30bf\u3092\u4f5c\u6210\u3067\u304d\u307e\u305b\u3093\u3002",//no i18n
	"crm.storage.create.error.client":"\u7ba1\u7406\u8005\u306e\u30c7\u30fc\u30bf\u5bb9\u91cf\u304c\u4e0a\u9650\u306b\u9054\u3057\u305f\u305f\u3081\u3001\u65b0\u3057\u3044\u30c7\u30fc\u30bf\u3092\u4f5c\u6210\u3067\u304d\u307e\u305b\u3093\u3002\u3053\u306e\u554f\u984c\u3092\u89e3\u6c7a\u3059\u308b\u306b\u306f{0}\u306b\u3054\u9023\u7d61\u304f\u3060\u3055\u3044\u3002",//no i18n
	"crm.storage.avail.info":"\uff08{0}/{1}\uff09",//no i18n
	"crm.storage.error.key.manage":"\u81ea\u5206\u306e\u30c7\u30fc\u30bf\u5bb9\u91cf\u3092\u7ba1\u7406",//no i18n
	"Records":"\u30c7\u30fc\u30bf",//no i18n
	"crm.workflow.alert.additional.recipients" : "\u8ffd\u52a0\u306e\u53d7\u4fe1\u8005", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "\u30e1\u30fc\u30eb\u30a2\u30c9\u30ec\u30b9\u306f\u3001\u30b3\u30f3\u30de\u533a\u5207\u308a\u3067\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044\u3002", //NO I18N
	"crm.related.contact.account" : "{1}\u306b\u95a2\u9023\u3059\u308b{0}",//No I18n
	"crm.allcontact.show" : "\u3059\u3079\u3066\u306e{0}",//No I18n
	"crm.button.mass.show" : "\u8868\u793a",//No I18n
	"crm.msg.custom.view.not.replied" : "\u672a\u8fd4\u4fe1\u306e\u30e1\u30c3\u30bb\u30fc\u30b8", //NO I18N
	"crm.msg.custom.view.replied" : "\u8fd4\u4fe1\u6e08\u307f\u306e\u30e1\u30c3\u30bb\u30fc\u30b8",//NO I18N
	"crm.workflow.select.recipients" : "\u53d7\u4fe1\u8005\u306e\u9078\u629e", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"\u3044\u305a\u308c\u304b\u306e\u6a29\u9650\u3092\u9078\u629e\u3057\u3066\u304f\u3060\u3055\u3044\u3002",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "\u30c7\u30d5\u30a9\u30eb\u30c8\u306e\u30d7\u30ed\u30d5\u30a1\u30a4\u30eb\u306f\u524a\u9664\u3067\u304d\u307e\u305b\u3093\u3002",//NO I18N
	"crm.inv.label.add.emails" : "\u30e1\u30fc\u30eb\u3092\u8ffd\u52a0\u3059\u308b", //NO I18N
	"crm.prediction.analytics.filter.year":"\u53bb\u5e74",//no i18n
	"Previous\ FY":"\u524d\u4f1a\u8a08\u5e74\u5ea6",//no i18n
	"Current\ FY":"\u4eca\u4f1a\u8a08\u5e74\u5ea6",//no i18n
	"Next\ FY":"\u7fcc\u4f1a\u8a08\u5e74\u5ea6",//no i18n
	"Current\ FQ":"\u4eca\u56db\u534a\u671f",//no i18n
	"Next\ FQ":"\u7fcc\u56db\u534a\u671f",//no i18n
	"Previous\ FQ":"\u524d\u56db\u534a\u671f",//no i18n
	"crm.label.next.year":"\u6765\u5e74",//no i18n
	"crm.picklist.sample.text":"\u30b5\u30f3\u30d7\u30eb\u30c6\u30ad\u30b9\u30c8",//no i18n
	"crm.more.colors":"\u305d\u306e\u4ed6\u306e\u8272",//no i18n
	"crm.label.simply.in":"\u5BFE\u8C61",//no i18n
	"crm.button.back.alone":"\u623b\u308b",//no i18n
	"crm.field.label.email":"\u30e1\u30fc\u30eb\u30a2\u30c9\u30ec\u30b9",//no i18n
"crm.zia.nba.feature.label":"\u6b21\u306e\u6700\u9069\u306a\u9867\u5ba2\u5bfe\u5fdc",//no i18n
"Meeting":"\u4e88\u5b9a",//no i18n
"Tomorrow":"\u660e\u65e5",//no i18n
"crm.zia.nbx.filter.due":"\u671f\u9650",//no i18n
"abm.segment": "\u30bb\u30b0\u30e1\u30f3\u30c8", // NO I18N
	"abm.segment.names": "\u30bb\u30b0\u30e1\u30f3\u30c8\u540d", // NO I18N
	"abm.abm.segment.name": "ABM\u30bb\u30b0\u30e1\u30f3\u30c8\u540d", // NO I18N
	"abm.segmentation.techniques": "ABM\u30bb\u30b0\u30e1\u30f3\u30c6\u30fc\u30b7\u30e7\u30f3\u6280\u8853", // NO I18N
	"abm.filters": "ABM\u30d5\u30a3\u30eb\u30bf\u30fc", // NO I18N
	"abm.filters.info": "\u3053\u308c\u3089\u306e\u30d5\u30a3\u30eb\u30bf\u30fc\u306f\u3001{0}\u3068\u95a2\u9023\u3065\u3051\u3089\u308c\u305f{1}\u306b\u57fa\u3065\u3044\u3066\u60c5\u5831\u3092\u8868\u793a\u3057\u307e\u3059\u3002", // NO I18N
	"abm.rfm.label": "RFM\u30e9\u30d9\u30eb", // NO I18N
	"abm.firmographics.label": "\u30d5\u30a1\u30fc\u30de\u30b0\u30e9\u30d5\u30a3\u30c3\u30af\u30b9\u30e9\u30d9\u30eb" ,// NO I18N
	"abm.recommendation.label": "\u63a8\u5968\u30e9\u30d9\u30eb", // NO I18N
	"abm.voc.label": "VOC\u30e9\u30d9\u30eb", // NO I18N
	"abm.engagement.label": "\u30a8\u30f3\u30b2\u30fc\u30b8\u30e1\u30f3\u30c8\u30e9\u30d9\u30eb", // NO I18N
	"abm.overall.score": "\u5168\u4f53\u30b9\u30b3\u30a2", // NO I18N
	"abm.mood.score": "\u30e0\u30fc\u30c9\u30b9\u30b3\u30a2", // NO I18N
	"abm.value.score": "\u5024\u30b9\u30b3\u30a2", // NO I18N
	"abm.engagement.score": "\u30a8\u30f3\u30b2\u30fc\u30b8\u30e1\u30f3\u30c8\u30b9\u30b3\u30a2", // NO I18N
	"abm.rfm.score": "RFM\u30b9\u30b3\u30a2", // NO I18N
	"crm.customview.pin.column":"\u5217\u3092\u56fa\u5b9a\u3059\u308b",//no i18n
	"crm.customview.unpin.column":"\u5217\u306e\u56fa\u5b9a\u3092\u89e3\u9664\u3059\u308b",//no i18n
	"crm.column.list.max.group.limit" : "", //no i18n
	"crux.custom.field.greater.than.equalto":"{0}は{1}以上にしてください。",
	"crux.users.selected.plural" : "{0}人のユーザーが選択されました。",
	"crux.user.selected.singular" :"{0}人のユーザーが選択されました。",
	"crux.criteria.empty.secondaryfield.module" : "一致した{0}フィールドは{1}モジュールに見つかりません",
	"crux.criteria.empty.secondaryfield" : "他の{0}フィールドは比較に利用できません。比較する値を入力してください。",
	"crux.logged.in.role.definition" : "レコードアクションを起動するユーザーのロール",
"crm.competitoralert.mentioned.in.email":"<b>\u30e1\u30fc\u30eb</b>\u3067\u306e\u8a00\u53ca",
"crm.competitoralert.with.sentiment":"\u611f\u60c5\u306e\u5206\u6790\u60c5\u5831\u3092\u542b\u3080",
"crm.competitoralert.name.error":"\u7af6\u5408\u4ed6\u793e\u540d\u306b\u95a2\u3057\u3066\u3001\u6709\u52b9\u306a\u5024\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044\u3002",
"crm.competitoralert.date.error":"\u7af6\u5408\u4ed6\u793e\u304c\u30e1\u30fc\u30eb\u3067\u8a00\u53ca\u3055\u308c\u305f\u65e5\u4ed8\u306b\u95a2\u3057\u3066\u3001\u6709\u52b9\u306a\u5024\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044\u3002",
"crm.competitoralert.sentiment.error":"\u7af6\u5408\u4ed6\u793e\u306e\u5370\u8c61\u306b\u95a2\u3057\u3066\u3001\u6709\u52b9\u306a\u5185\u5bb9\u3092\u9078\u629e\u3057\u3066\u304f\u3060\u3055\u3044\u3002",
"crm.competitor.name":"\u7af6\u5408\u4ed6\u793e",
	"crux.max.limit.unselect" : "{0} {1}以上を選択解除することはできません。", //NO I18N
	"crux.existing.tag" : "\u300c{0}\u300dはすでに選択されています", //NO I18N
	"crm.label.true" : "\u771f",//NO I18N
	"crm.label.false" : "\u507d",//NO I18N
	"crm.record.locked" : "\u30ed\u30c3\u30af\u4e2d",//NO I18N
	"crm.condition.belongs.to" : "{0}\u306b\u95a2\u9023\u4ed8\u3051\u3089\u308c\u3066\u3044\u308b", //NO I18N
	"crm.condition.not.belongs.to" : "{0}\u306b\u95a2\u9023\u4ed8\u3051\u3089\u308c\u3066\u3044\u306a\u3044", //NO I18N
	"crm.filter.number.range":"{0}\u304b\u3089{1}\u306e\u9593\u306e\u5024",//no i18n
	"crm.smartfilter.picklist.options.msg":"10\u4ef6\u3092\u8d85\u3048\u308b\u9078\u629e\u5024\u306b\u5bfe\u3057\u3066\u3001\u3053\u306e\u5185\u5bb9\u3092\u9078\u629e\u3059\u308b\u3053\u3068\u306f\u3067\u304d\u307e\u305b\u3093\u3002",//no i18n
	"crm.chosen.maximum.campaigns.selected":"5\u4ef6\u3088\u308a\u591a\u304f{0}\u3092\u9078\u629e\u3059\u308b\u3053\u3068\u306f\u3067\u304d\u307e\u305b\u3093",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"{0}\u4ef6\u3092\u8d85\u3048\u308b{1}\u3092\u9078\u629e\u3059\u308b\u3053\u3068\u306f\u3067\u304d\u307e\u305b\u3093",//no i18n
	"crm.best.time.column.label":"\u9023\u7d61\u306b\u6700\u9069\u306a\u6642\u9593\u5e2f",//no i18n
	"crm.email.unblock.filter.temporary":"\u4e00\u6642\u7684",
	"crm.email.unblock.filter.permanent":"\u5e38\u6642",
	"crm.email.unblock.filter.both":"\u4e21\u65b9",
	"crm.email.unblock.filter.category":"\u30ab\u30c6\u30b4\u30ea\u30fc",
"crm.lookup.advance.error.msg" : "\u30d5\u30a3\u30eb\u30bf\u30fc\u306e\u9069\u7528\u4e2d\u306b\u30a8\u30e9\u30fc\u304c\u767a\u751f\u3057\u307e\u3057\u305f\u3002\u30da\u30fc\u30b8\u3092\u66f4\u65b0\u3057\u3066\u304b\u3089\u3001\u3082\u3046\u4e00\u5ea6\u304a\u8a66\u3057\u304f\u3060\u3055\u3044\u3002",
	"Contact\ Name" : "{0}\u540d",
	"Lead\ Name" : "{0}\u540d",
	"-None-" : "\u306a\u3057",
	"January" : "1\u6708",
	"February":"2\u6708",
	"March":"3\u6708",
	"April":"4\u6708",
	"May":"5\u6708",
	"June":"6\u6708",
	"July":"7\u6708",
	"August":"8\u6708",
	"September":"9\u6708",
	"October":"10\u6708",
	"November":"11\u6708",
	"December":"12\u6708",
	// Image Upload Open
	"crm.label.browse.files":"\u30d5\u30a1\u30a4\u30eb\u3092\u9078\u629e",//no i18n
	"crm.label.lowercase.or":"\u307e\u305f\u306f",//no i18n
	"crm.image.header":"\u753b\u50cf",//no i18n
	"crm.image.n.n":"\u4efb\u610f\u306e\u6bd4\u7387",//no i18n
	"crm.image.2.2":"2\u5bfe2\u306e\u6bd4\u7387",//no i18n
	"crm.image.4.3":"4\u5bfe3\u306e\u6bd4\u7387",//no i18n
	"crm.image.16.9":"16\u5bfe9\u306e\u6bd4\u7387",//no i18n
	"crm.image.height.width.swap":"\u9ad8\u3055\u3068\u5e45\u3092\u5165\u308c\u66ff\u3048\u308b",//no i18n
	"crm.image.rotate.image":"\u56de\u8ee2\u3059\u308b",//no i18n
	"crm.label.lowercase.of":"\uff0f",//no i18n
	"crm.image.empty.name.placeholder" : "\u540d\u524d\u3092\u5165\u529b",
	"crm.image.crop.and.rotate" : "\u5207\u308a\u53d6\u308a\u3068\u56de\u8ee2",
	"crm.image.description" : "\u8aac\u660e\u3092\u8ffd\u52a0\u2026\u2026",
	"crm.image.actual.size" : "\u5b9f\u969b\u306e\u30b5\u30a4\u30ba",
	"crm.image.reset" : "\u30ea\u30bb\u30c3\u30c8\u3059\u308b",
	"crm.image.width.px" : "px",
	"crm.view.attachment.download":"\u30c0\u30a6\u30f3\u30ed\u30fc\u30c9\u3059\u308b",//no i18n
	"crm.label.edit":"\u7de8\u96c6\u3059\u308b",//no i18n
	"crm.label.close":"\u9589\u3058\u308b",//no i18n
	"crm.label.any":"\u3044\u305a\u308c\u304b",
	"crm.image.description":"\u8aac\u660e\u3092\u8ffd\u52a0\u2026\u2026",//no i18n
	"crm.image.zoom.in":"\u62e1\u5927",//no i18n
	"crm.image.zoom.out":"\u7e2e\u5c0f",//no i18n
	"crm.label.lowercase.of":"\uff0f",//no i18n
	"crm.image.desc.maxsize":"\u8aac\u660e\u306f255\u6587\u5b57\u4ee5\u4e0b\u306b\u3057\u3066\u304f\u3060\u3055\u3044\u3002",//no i18n
	"crm.image.name.maxsize":"\u540d\u524d\u306f100\u6587\u5b57\u4ee5\u4e0b\u306b\u3057\u3066\u304f\u3060\u3055\u3044\u3002",//no i18n
	"crm.fileuploader.message.totalfilesizeexceeded":"\u6dfb\u4ed8\u30d5\u30a1\u30a4\u30eb\u306e\u5408\u8a08\u30b5\u30a4\u30ba\u304c\u4e0a\u9650\uff08{0}\uff09\u3092\u8d85\u3048\u3066\u3044\u307e\u3059\u3002",//no i18n
	"crm.fileuploader.message.maxfilesexceeded":"\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u3067\u304d\u308b\u30d5\u30a1\u30a4\u30eb\u6570\u306e\u4e0a\u9650\u306f\u3001{0}\u4ef6\u3067\u3059\u3002",//no i18n
	"crm.fileuploader.message.maxfilesexceeded1":"\u6dfb\u4ed8\u3067\u304d\u308b\u30d5\u30a1\u30a4\u30eb\u6570\u306e\u4e0a\u9650\u306f\u3001{0}\u4ef6\u3067\u3059\u3002",//no i18n
	"crm.ImageuploadField.size.limit":"\u6dfb\u4ed8\u30d5\u30a1\u30a4\u30eb\u306e\u753b\u50cf\u30b5\u30a4\u30ba\u306e\u5408\u8a08\u304c\u4e0a\u9650\uff08{0}MB\uff09\u3092\u8d85\u3048\u3066\u3044\u307e\u3059\u3002",//no i18n
	"crm.attachment.imageUploadField.Minlen.check":"\u753b\u50cf\u30921\u4ef6\u306e\u307f\u9078\u629e\u3067\u304d\u307e\u3059",//no i18n
	"crm.image.crop.error":"\u753b\u50cf\u30c7\u30fc\u30bf\u3092\u53d6\u5f97\u3067\u304d\u307e\u305b\u3093\u3067\u3057\u305f\u3002\u3082\u3046\u4e00\u5ea6\u304a\u8a66\u3057\u304f\u3060\u3055\u3044\u3002",//no i18n
	"crm.attach.here":"\u3053\u3061\u3089\u3002",//no i18n
	"crm.image.unsupported.corrupted.single":"\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u3057\u3088\u3046\u3068\u3057\u3066\u3044\u308b\u5199\u771f\u304c\u7834\u640d\u3057\u3066\u3044\u307e\u3059\u3002",//no i18n
	"Browse":"\u53c2\u7167",//no i18n
	"crm.imageupload.failure":"\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u3067\u304d\u306a\u304b\u3063\u305f\u753b\u50cf\u304c\u3042\u308a\u307e\u3059\u3002\u6642\u9593\u3092\u304a\u3044\u3066\u3001\u3082\u3046\u4e00\u5ea6\u304a\u8a66\u3057\u304f\u3060\u3055\u3044\u3002",//no i18n
	"zc.editor.width":"\u5e45",//no i18n
	"ze.editor.height":"\u9ad8\u3055",//no i18n
	"crm.label.delete":"\u524a\u9664\u3059\u308b",//no i18n
	"crm.image.error.gif":"GIF\u5f62\u5f0f\u306e\u753b\u50cf\u306f\u5207\u308a\u53d6\u308a\u3067\u304d\u307e\u305b\u3093\u3002",//no i18n
	"crm.fileuploader.message.morefiles":"\u305d\u306e\u4ed6{0}\u4ef6\u306e\u30d5\u30a1\u30a4\u30eb",//no i18n
	"crm.fileuploader.message.invalidfileType1":"\u30d5\u30a1\u30a4\u30eb\u300c{0}\u300d\u306f\u30b5\u30dd\u30fc\u30c8\u3055\u308c\u3066\u3044\u307e\u305b\u3093\u3002",//no i18n
	"crm.fileuploader.message.invalidfileType2":"\u30d5\u30a1\u30a4\u30eb\u300c{0}\u300d\u3068\u300c{1}\u300d\u306f\u30b5\u30dd\u30fc\u30c8\u3055\u308c\u3066\u3044\u307e\u305b\u3093\u3002",//no i18n
	"crm.attach.upload.sizelimit":"\u5408\u8a08\u30b5\u30a4\u30ba\u306e\u4e0a\u9650",//no i18n
	
	
	
	
	"crm.fileuploader.message.maxfilesexceeded" : "\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u3067\u304d\u308b\u30d5\u30a1\u30a4\u30eb\u6570\u306e\u4e0a\u9650\u306f\u3001{0}\u4ef6\u3067\u3059\u3002",
"crm.file.upload.maxlength.exceeds" : "{0}\u3067\u306f\u30011\u4ef6\u306e\u30d5\u30a1\u30a4\u30eb\u306e\u307f\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u3067\u304d\u307e\u3059\u3002.",
	    "crm.label.series":"\u6d3b\u52d5\u30d7\u30ed\u30bb\u30b9",
	 "Start Date":"\u958b\u59cb\u65e5",
	 "End Date" : "\u7d42\u4e86\u65e5",
	 "Enrolled By" : "\u9069\u7528\u3057\u305f\u30e6\u30fc\u30b6\u30fc",
	 "Unenrolled Date" : "\u9069\u7528\u89e3\u9664\u65e5",
	 "Last Follow Up Date" : "\u6700\u5f8c\u306e\u30d5\u30a9\u30ed\u30fc\u30a2\u30c3\u30d7\u65e5\u6642",
	 "Cadence Status" : "\u9023\u7d9a\u51e6\u7406\u306e\u30b9\u30c6\u30fc\u30bf\u30b9",
	 "Member Status" : "\u5bfe\u8c61\u8005\u306e\u30b9\u30c6\u30fc\u30bf\u30b9",
	 "Last Follow up Response" : "\u6700\u5f8c\u306e\u30d5\u30a9\u30ed\u30fc\u30a2\u30c3\u30d7\u3078\u306e\u53cd\u5fdc",
	 "Follow Up Type" : "\u30d5\u30a9\u30ed\u30fc\u30a2\u30c3\u30d7\u306e\u7a2e\u985e",
	"December":"12\u6708",
	"crm.label.add.tags":"\u30bf\u30b0\u3092\u8ffd\u52a0\u3059\u308b",//no i18n
	"crm.confirm.deassociate":"\u5272\u308a\u5f53\u3066\u3092\u89e3\u9664\u3059\u308b"//no i18n
}
